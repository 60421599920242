import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCarePlanning = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    
  return (
  <PageLayout>
    <SEO
      title="Long Term Care Planning | The LTCPG Process"
      ogDescription="Long term care planning is a crucial first step to purchasing insurance and protecting your assets for your family's future."
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h1>
            Individual Long Term Care Planning Process
          </h1>
          <p>The idea of protecting your assets, income, health and family investments is probably not new to you. You protect your home with homeowner’s insurance. You use health insurance to manage expenses associated with your health. With your income can be protected by disability or income insurance. Your family is protected by life insurance. Sometimes you protect your wealth with additional life insurance.</p>

          <p>What are your reasons for building up these investments? For most people, building wealth means being able to enjoy your retirement and perhaps passing some of it along to your family.</p>
          <RegisterForWebinarButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_386152001.jpg"
            alt="Long Term Care Planning"
          />
        </div>
      }
    />
    <QuotePanel text="Hello World" person="John Doe" />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img
            src="/images/AdobeStock_318153694.jpg"
            alt="Long Term Care Planning - Protecting Your Future"
          />
        </div>
      }
      textRight={
        <div>
          <h2>Protecting Your Family's Future</h2>

          <p>What protection do you have in place to ensure those assets can provide the way you’ve wanted and intended? Long term care planning protects your IRAs, 40lks as an investment so your other wealth assets can provide as you’ve intended in the event that you need long term care assistance. It ensures your investments can survive the cost of long term care.</p>

          <p>You probably want to know the answer to questions like “What if I don't take the step to protect my 401k's IRAs and investments with long term care?” We advise people if the issue of long term care does not present itself to you and your family, your investments are probably going to be fine and left intact.</p>

          <p>However, if the long term care issue and its <a href="/why-get-long-term-care-insurance/">associated challenges</a> do happen to you and you haven't taken this step in planning, it is very likely your 401ks, your IRAs and investments will end up paying for the long term care expenses. And for as long you or a loved one needs long term care.</p>
        </div>
      }
    />
    <ParallaxSection src={`/images/parallax/AdobeStock_340362057.jpg`} height={500} />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>Long Term Care Planning Education</h2>

          <p>The Long Term Care Planning Group helps people understand <a href="/long-term-health-care/">what long term care is</a> and, perhaps more importantly, what it isn't.</p>

          <p>We optimize plans individually based on the client's health, funding and planning needs. Every plan can be catered specifically to that person.</p>

          <p>Another part of the process of long term care planning is considering the <a href="/impact-of-caregiving/">impact on your family</a> if you’re relying on a son or daughter to become your caregiver. Without long term care planning, there are several unintended, but serious relationship strains that can happen.</p>

          <p>In many families, it’s assumed that caregiving for an older adult will be provided by a younger family member. While the experience of caregiving can be rewarding for some, the physical, financial, emotional, and psychological strain of caregiving can have wide-reaching impact on the family and friends of caregivers.  Here are just a few important metrics to consider:</p>

          <p>52% of family caregivers did feel qualified to provide physical care</p>
          <p>70% of family caregivers missed some time from work</p>
          <p>56% of caregivers have children under the age of 18</p>
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_374375521.jpg"
            alt="Long Term Care Planning - Family Relationships"
          />
          <Blockquote>One of the greatest rewards of long term care planning is knowing your family relationships will be preserved. Your son or daughter who is working and raising a family will respect and appreciate your long term care planning so they can focus on loving you and spending quality time with you.</Blockquote>
          <ScheduleVisitButton />
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_175442176.jpg"
          alt="Long Term Care Planning Process"
        className="right-point"
        />
      }
      imageGrow
      textPadded
      text={
        <div>
          <h2>Our Long Term Care Planning Process</h2>

          <p>Here’s our step-by-step process for your long term care planning.</p>

          <ol>
            <li>What is Long Term Care?</li>
            <li>Your experience with Long Term Care</li>
            <li>Continuum of Long Term Care services and housing</li>
            <li>History of Long Term Care</li>
            <li>How to access the benefits of Long Term Care</li>
            <li>Federal Government’s position on Long Term Care</li>
            <li>Where Long Term Care fits in with your overall financial plan</li>
            <li>Long Term Care Planning spectrum of options</li>
            <li>Long Term Care Carrier Market Space</li>
            <li>Basic Long Term Care Planning Metrics</li>
            <li>Cost of Long Term Care in your area(s)</li>
            <li>Role that health, medications and medical history plays</li>
            <li>Long Term Care funding options
              <ol type="a">
                <li>Traditional Health Long Term Care</li>
                <li>Asset Based Long Term Care</li>
              </ol>
            </li>
            <li>Issues associated with Long Term Care Premium Pricing</li>
            <li>Role of Long Term Care Partnership</li>
          </ol>
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_107811517.jpg"
          alt="Long Term Care Planning Discovery Process"
          className="left-point"
        />
      }
      imageRight
      imageGrow
      textPadded
      text={
        <div>
          <h2>Discovery</h2>

          <ol>
            <li>10 year medical profile, surgical history and medications</li>
            <li>Funding sources</li>
          </ol>

          <h2>Exploration Process</h2>

          <ol>
            <li>Review of your health and medical history with the appropriate LTC carriers</li>
            <li>Discussion via email of applicable carriers and preliminary premium pricing</li>
          </ol>

          <h2>Application Secured</h2>

          <ol>
            <li>Complete application for chosen carrier</li>
            <li>Discussion of submitted plan design</li>
            <li>Collect 2-3 months premium for chosen carrier</li>
          </ol>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>Underwriting and Expectation Setting</h2>

          <ol>
            <li>Discussion and review of underwriting classifications</li>
            <li>Underwriting can take 4-6 weeks and typically includes the following requirements:
              <ol type="a">
                <li>Thorough review of your application by carrier</li>
                <li>Prescription Drug Report</li>
                <li>Securing of your medical records (APS)</li>
                <li>Phone Health Interview (PHI)</li>
              </ol>
            </li>
            <li>Additional underwriting requirements may include:
              <ol type="a">
                <li>Phone Cognitive Interview</li>
                <li>Face to Face Interview</li>
                <li>Paramedical Exam</li>
              </ol>
            </li>
          </ol>
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_215583258.jpg"
            alt="Long Term Care Planning Underwriting"
          />
          <RegisterForWebinarButton />
        </div>
      }
    />
    <PageWrapper maxWidth color="green">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/corey-rieck-long-term-planning-group.jpg"
              alt="Long Term Care Planning Carriers"
            />
          </div>
        }
        textRight={
          <div>
            <h2>Underwriting Decision by Carrier</h2>

            <h3>Policy Delivery</h3>

            <ol>
              <li>Review of underwritten policy long term care planning metrics</li>
              <li>Any alterations of policy discussed and implemented</li>
            </ol>

            <h3>Continued Client Contact and Support</h3>

            <p>Give us a call to confidentially discuss your long term care planning needs options, <a href="tel:6788145088">(678) 814-5088</a>. This is a complimentary, no-obligation conversation.</p>
            <ScheduleVisitButton />
          </div>
        }
      />
    </PageWrapper>
  </PageLayout>
  )
}

export default LongTermCarePlanning
